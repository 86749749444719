import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-common-right-sidebar',
  templateUrl: './common-right-sidebar.component.html',
  styleUrls: ['./common-right-sidebar.component.scss']
})
export class CommonRightSidebarComponent implements OnInit {

  public showNotification = false
  public notifications = [];
  constructor(
    private router: Router,
    private usersService: UsersService,
    protected sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.usersService.getNotifications().subscribe((result: any) => {
      this.notifications = result.payload.list;
    })
  }

  navigateNotification() {
    this.router.navigateByUrl(`notifications`);
  }
}
