import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersService } from '../services/users.service';

@Injectable({
  providedIn: 'root'
})
export class RolesResolverResolver implements Resolve<boolean> {
  constructor(private usersService: UsersService) {}
  resolve(): Observable<boolean> {
    return this.usersService.getRoles().pipe(map((data: any) => {
      sessionStorage.setItem('roles', JSON.stringify(data.payload));
      return true;
    }));
  }
}
