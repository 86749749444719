import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event, RoutesRecognized } from '@angular/router';
import { Location } from '@angular/common';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { EntityService } from 'src/app/shared/services/entity.service';

@Component({
  selector: 'app-onboarding-layout',
  templateUrl: './onboarding-layout.component.html',
  styleUrls: ['./onboarding-layout.component.scss']
})
export class OnboardingLayoutComponent implements OnInit {

  public step;
  public header: any;
  public title: string;
  public subTitle: string;
  public questionIcon = false;
  public pageInfo;
  subscriptions: Subscription[] = [];
  entityName: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private entityService : EntityService,
    private router: Router
  ) {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
    .subscribe(event => {
      this.titleService.setTitle(event['title']);
      this.pageInfo = event;
      if(this.pageInfo.showEntityName) {
          this.getRootEntity();
      }
    });
   }


  private urlToKeyMap = new Map([
    ['account', { title: 'Create an account', subTitle: 'Login details' }],
    ['profileSetup', { title: 'Create an account', subTitle: 'Profile details'}],
    ['chooseYourself', { title: 'How do you best describe yourself?', subTitle: 'Choose the one according to your needs.' }],
    ['createEntity', { title: 'Create an entity', subTitle: 'You can create entities whose compliance you can manage through this software. Get your first one created now' }],
    ['questionnaire', { title: 'What do you want to do next ?', subTitle: 'Select between answering a questionnaire or choosing modules for your entity' }],
    ['questions', { title: 'Questionnaire', subTitle: 'We need to know some characteristisc about Ubisoft so we can recommend the modules that apply' }],
    ['selectModules', { title: 'Select Modules', subTitle: 'Below are the modules applicable to your entity Ubisoft. Please choose ones you want to use TaskManager for'}],
    ['orderDetails', { title: 'Order Summary ', subTitle: 'Your selected modules'}],
    ['tasks', {title: 'Continous Compliance Tasks', subTitle: 'There are tasks that you need to comply with always. The system can remind you of the task at regular frequencies. Choose those frequencies for Ubisoft'}],
    ['users', {title: 'Users', subTitle: 'You can invite others to work on the compliance of your entity Ubisoft. Use the email addresses of the folks to invite them to collaborate'}],
    ['userView', {title: 'Users', subTitle: 'You can invite others to work on the compliance of your entity Ubisoft. Use the email addresses of the folks to invite them to collaborate'}],
    ['invite', { title: 'Invite Others', subTitle: 'Provide list of email addresses here, separated by semi-colons. An invitation will be sent to them to register and collaborate on this entity'}],
    ['theme', { title: 'Select a theme', subTitle: 'Select a theme for your entities and for the dashboad'}],
    ['entityCreated', { title: 'Entity Created', subTitle: 'Your entity is now created - you can start tracking the tasks, completing them and ensuring you are compliant with the law.'}],
    ['userCreated', { title: 'User Created', subTitle: 'The user account is created - Add an entity to start tracking the tasks, completing them and ensuring you are compliant with the law.'}]
  ]);

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
      }
  });
    // this.step = this.router.url.split('/').pop();
    // this.header = this.urlToKeyMap.get(this.step);
    // this.title = this.header.title;
    // this.subTitle = this.header.subTitle;
    // this.questionIcon = this.header.questionTip;
  }


  back() {
    this.location.back();
  }
  
  getRootEntity(): void {
    this.subscriptions.push(
      this.entityService.getRootEntity()
        .subscribe((res: any) => {
          if(res.payload) {
            this.entityName = res.payload.displayName;
          }
        }, (error: any) => {
        }),
    );
}
}
