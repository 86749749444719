import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProxyEventService {

  popUp: any = '';
  private closePopup = new BehaviorSubject(this.popUp);
  closeSidebar = this.closePopup.asObservable();

  entity: any = '';
  private details = new BehaviorSubject(this.entity);
  entityDetails = this.details.asObservable();
  constructor() { }

  closeAnyPopup(type: any) {
    if (type) {
        this.closePopup.next(type);
    }
  }

  getDetails(type: any) {
    if (type) {
        this.details.next(type);
    }
  }
}
