import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserRolesService } from '../../services/user-roles.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-common-invite-email',
  templateUrl: './common-invite-email.component.html',
  styleUrls: ['./common-invite-email.component.scss']
})
export class CommonInviteEmailComponent implements OnInit {

  invitationForm: FormGroup;
  subscriptions: Subscription[] = [];
  roles = [];
  public entityId: string;
  public invitationSent: string;
  public currentUrl: string;
  public roleid:string;
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private rolesService: UserRolesService,
    private usersService: UsersService,
    private toastr: ToastrService

  ) { }

  get email() {
    return this.invitationForm.get("email");
  }

  get role() {
    return this.invitationForm.get("role");
  }

  ngOnInit() {
    this.initializeInvitation();
    this.getAllRoles();
    this.activatedRoute.params.subscribe(params => {
      if (params.step) {
        this.invitationSent = params.step;
        
      }
      if (params.entityUuid) {
        this.entityId = params.entityUuid;
        this.currentUrl = this.router.url.split('/')[1];
      }
      
    });
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.roleid=params.role
      })
  }

  initializeInvitation() {
    this.invitationForm = this.fb.group({
      email: ["", Validators.compose([Validators.required,Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
      role: ["", Validators.compose([Validators.required])],
    });
  }

  inviteViaEmail(): void {
    this.markFormControlsAsDirty(this.invitationForm);
    if (this.invitationForm.valid) {
      this.subscriptions.push(
        this.usersService.inviteViaEmail(this.invitationForm.value, this.entityId)
          .subscribe((res: any) => {
            this.navigateInvitationSent();
          }, (error: any) => {
          }),
      );
    }
    else{
      this.toastr.error( 'Invalid Email');
    }
  }

  getAllRoles() {
    this.subscriptions.push(
      this.rolesService.getAllRoles()
        .subscribe((res: any) => {
          if(res){
            this.roles = [];
            this.roles = res.payload;
          }
        }, (error: any) => {
        }),
    );
  }


  /**
* Catchs server error
* @param errors of thpe object
* @param formName is a form
*/
catchServerError(errors, formName: FormGroup) {
  const error = errors.error;
  if (error.payload) {
    const keys = Object.keys(error.payload);
    const data = error.payload;
    keys.forEach((key) => {
      const formControlAvailable = formName.get(key);
      if (formControlAvailable) {
        formName.get(key).setErrors({ server: data[key] });
        formName.get(key).markAsDirty();
      }
    });
  } else {
     this.toastr.error(error.message, 'Error');
  }
}

/**
 * Marks form controls as dirty
 * @param form of type formgroup
 */
markFormControlsAsDirty(form: FormGroup) {
  const keys = Object.keys(form.controls);
  keys.forEach(key => {
    const formControlAvailable = form.get(key);
    if (formControlAvailable) {
      form.get(key).markAsDirty();
    }
  });
}

navigateInvitationSent() {
  this.router.navigateByUrl(`${this.currentUrl}/${this.entityId}/invite/sent`);
}

navigateInvite() {
  this.router.navigateByUrl(`${this.currentUrl}/${this.entityId}/invite`);
}
}
