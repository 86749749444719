import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IForgetPassword, ILogin, IResetPassword } from '../interface/auth.interface';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated = false;
  // public isAuthenticated: boolean

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) {
  }

  /**
   * Logins auth service
   * @param body of type Login interface
   * @returns  user details object
   */
  login(body: ILogin) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.CUSTOMER}/login`),
      body
    );
  }


  /**
   * signup auth service
   * @param body of type Login interface
   * @returns  token
   */
  signup(body: ILogin) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.CUSTOMER}${this.constantService.SIGNUP}`),
      body
    );
  }

  /**
   * profileSetup auth service
   * @returns  profile info
   */
  profileSetup(body) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/me/profile`),
      body
    );
  }


  /**
   * Forgets password
   * @param body  of type ForgetPassword interface
   * @returns  object
   */
  forgetPassword(body: IForgetPassword) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.CUSTOMER}${this.constantService.FORGETPASSWORD}`),
      body
    );
  }


  /**
 * Resets password
 * @param body Password as string
 * @param token  token as string
 * @returns password IResPutAccount
 */
  resetPassword(body: IResetPassword, token: string) {
    return this.http.patch(this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.CUSTOMER}${this.constantService.RESETPASSWORD}/${token}`),
      body,
    );
  }

   /**
   * Gets account details
   * @returns account details 
   */
  getAccountDetails() {
    return this.http.get(this.constantService.getUrl(`${this.constantService.CUSTOMERS}/me`));
  }

  verify(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.CUSTOMER}/verify`),
      body
    );
  }

  resendVerifyLink(id) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.SESSIONS}${this.constantService.CUSTOMER}/verify/resend`),
      {id}
    );
  }
}
