import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponentsModule } from './components/shared-component-module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedPipesModule } from './pipes/shared-pipe.modulte';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    NgbModule,
  ]
})
export class SharedModule { }
