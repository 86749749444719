import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TasksService } from 'src/app/shared/services/tasks.service';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActionPointService } from '../../services/action-point.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-task-view',
  templateUrl: './common-task-view.component.html',
  styleUrls: ['./common-task-view.component.scss']
})
export class CommonTaskViewComponent implements OnInit {

  subscriptions: Subscription[] = [];
  notes = [
    { name: 'Overdue', className: 'overdue' },
    { name: 'Upcoming - Close', className: 'upcoming-close' },
    { name: 'Upcoming - Some time', className: 'upcoming-sometime' },
    { name: 'Far away', className: 'far-away' },
    { name: 'Completed', className: 'completed' },
    { name: 'Ignored', className: 'ignored' },
  ];
  showIgnored = false;
  public taskUuid: string;
  public entityUuid: string;
  public taskType: string;
  public actions = ['Delegate', 'Ignore', 'Mark as complete', 'Add pivot Date', 'Add attachment'];
  public taskComments = [];
  public commentBox: FormGroup;
  public taskAction = [];
  tasksDetails: any;
  public selectedActionUuid: string;
  image=[];
  constructor(
    private activatedRoute: ActivatedRoute,
    private tasksService: TasksService,
    private location: Location,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private actionPointService: ActionPointService,
  ) { }

  get message() {
    return this.commentBox.get('message');
  }

  ngOnInit() {
    this.initializeComment();
    this.taskType = this.router.url.split('/')[2];
    this.activatedRoute.params.subscribe(params => {
      if (params.taskUuid) {
        this.taskUuid = params.taskUuid;
        this.getTaskDetails();
        this.getActionPointByTask();
        this.getTaskComments();
      }
      if (params.entityUuid) {
        this.entityUuid = params.entityUuid;
      }
    });
  }

  hasPermission(actions: string[]) {
    if(!this.entityUuid) {
      return false;
    }
    const permissionsString = sessionStorage.getItem("roles");
    
    if(permissionsString) {
      const permissions = JSON.parse(permissionsString);
      const entityPermissions = permissions[this.entityUuid];
      if(entityPermissions) {
        if(entityPermissions.owner) {
          return true;
        }
        const status = actions.reduce((acc, action) => {
          if(entityPermissions.permissions.indexOf(action) !== -1) {
            acc = true;
          }
          return acc;
        }, false);
        if(status) {
          return true;
        }
      }
    }
    return false;
  }

  initializeComment() {
    this.commentBox = this.fb.group({
      message: [''],
    });
  }

  getColor(color) {
    if(color==='later'){
      return 'faraway-color';
    }
    if(color) {
      return color+'-color';
    }
  }

  getIgnore(ignore) {
    if (ignore) {
      return 'ignored-color';
    }
  }

  getBackgroundColor(color) {
    if(color==='later'){
      return 'faraway-background';
    }
    return color+'-background';
  }
  getIgnoreBackgroundColor(ignore) {
    if (ignore) {
      return 'ignored-background';
    }
  }
  getTaskDetails() {
    this.subscriptions.push(
      this.tasksService.getTaskDetails({ taskType: this.taskType }, this.taskUuid).subscribe((res: any) => {
        if (res) {
          this.tasksDetails = res.payload;
          this.entityUuid = res.payload.entity.uuid;
          this.getCollabaratorss(this.tasksDetails);
        }
      }, (error: any) => {
      }),
    );
  }

  getActionPointByTask() {
    if (this.taskType === 'custom') {
      this.subscriptions.push(
        this.actionPointService.getCustomActionPointByTask(this.taskUuid).subscribe((res: any) => {
          if (res) {
            this.taskAction = [];
            this.taskAction = res.payload.actions.filter(element => {
              element['expand'] = false;
              return element;
            });
          }
        }, (error: any) => {
        }),
      );
    } else {
      this.subscriptions.push(
        this.actionPointService.getActionPointByTask(this.taskUuid).subscribe((res: any) => {
          if (res) {
            this.taskAction = [];
            this.taskAction = res.payload.actions.filter(element => {
              element['expand'] = false;
              return element;
            });
          }
        }, (error: any) => {
        }),
      );
    }
  }

  getTaskComments() {
    this.subscriptions.push(
      this.tasksService.getTaskComments(this.taskUuid).subscribe((res: any) => {
        if (res) {
          this.taskComments = res.payload;
        }
      }, (error: any) => {
      }),
    );
  }

  addComments() {
    if (this.message.valid) {
      this.subscriptions.push(
        this.tasksService.addTaskComments(this.taskUuid, { comment: this.message.value }).subscribe((res: any) => {
          if (res) {
            this.getTaskComments();
            this.initializeComment();
          }
        }, (error: any) => {
        }),
      );
    }
  }

  ignoreTasks(uuid) {
    this.subscriptions.push(
      this.actionPointService.ignoreTasks(uuid).subscribe((res: any) => {
        if (res) {
          this.getActionPointByTask();
        }
      }, (error: any) => {
      }),
    );
  }
  reactivate(item) {
    this.actionPointService.reactivate(item.uuid).subscribe((res: any) => {
      if (res) {
        this.getActionPointByTask();
      }
    });
  }

  back() {
    this.location.back();
  }

  navigateActionPointDetails(uuid, index) {
    if (this.entityUuid) {
      this.router.navigateByUrl(`/entities/${this.entityUuid}/tasks/taskView/${this.taskUuid}/actionPointView/${uuid}/${index}`);
    } else {
      this.router.navigateByUrl(`/tasks/${this.taskType}/taskView/${this.taskUuid}/actionPointView/${uuid}/${index}`);
    }
  }

  getCollabarators(tasksDetails) {
    if(!tasksDetails || !tasksDetails.collaborators || !tasksDetails.collaborators.length) {
      return "";
    }
    const collabSet = new Set([...tasksDetails.collaborators.map(item => item.customer.uuid)]);

    return [...collabSet].map(item => {
      const customer = tasksDetails.collaborators.find(value => value.customer.uuid === item);
      if(customer) {
        return customer.customer.displayName;
      } 
      return null;
    }).filter(item => !!item).join(",")
  }
  getCollabaratorss(tasksDetails) {
    this.image=[];
    if(!tasksDetails || !tasksDetails.collaborators|| !tasksDetails.collaborators.length) {
      return "";
    }
    const collabSet = new Set([...tasksDetails.collaborators.map(item => item.customer.uuid)]);

    return [...collabSet].map(item => {
      const customer =tasksDetails.collaborators.find(value => value.customer.uuid === item);
      if(customer) {
       this.image.push(customer.customer.profilePic);
        return this.image;
      } 
    
    })
   }
  openDelegateModal(delegateModal, uuid) {
    this.selectedActionUuid = uuid;
    this.modalService.open(delegateModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'sm' })
      .result.then((result) => {
        if (result === 'success') {
          this.getActionPointByTask();
        }
      });
  }

  openAttachmentModal(attachmentModal, uuid) {
    this.selectedActionUuid = uuid;
    this.modalService.open(attachmentModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'md' })
      .result.then(() => 1);
  }

  openCompleteModal(completeModal, uuid) {
    this.selectedActionUuid = uuid;
    this.modalService.open(completeModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'md' })
      .result.then((result) => {
        if (result === 'success') {
          this.getActionPointByTask();
        }
      });
  }

}
