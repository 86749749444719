import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-news-popup',
  templateUrl: './news-popup.component.html',
  styleUrls: ['./news-popup.component.scss']
})
export class NewsPopupComponent implements OnInit {

  @Output("sendData")
  public sendData = new EventEmitter();
  public news ={
    marked: false,
    description: 'Facilisis leo vel fringilla est ullamcorper eget nulla. Nullam vehicula ipsum a arcu cursus vitae. Nisl vel pretium lectus quam id leo in vitae. At erat pellentesque adipiscing commodo elit at imperdiet dui. Eu mi bibendum neque egestas congue quisque egestas diam. Aliquam vestibulum morbi blandit cursus risus. Vitae nunc sed velit dignissim sodales ut.'
  };
  constructor() { }

  ngOnInit() {
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }

  markAsFavourite(news){
    
  }
}
