import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbDate, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ActionPointService } from '../../services/action-point.service';
import { UploadToS3Service } from '../../services/upload-to-s3.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-common-mark-complete',
  templateUrl: './common-mark-complete.component.html',
  styleUrls: ['./common-mark-complete.component.scss']
})
export class CommonMarkCompleteComponent implements OnInit {

  @Input("actionUuid")
  public actionUuid: string;

  @Output("sendData")
  public sendData = new EventEmitter();

  public markAsComplete: FormGroup;
  public showAttachments = false;
  subscriptions: Subscription[] = [];
  public uploadData: any;
  maxDate = undefined;
  selectedDate = undefined;
  formValidation: any;
  
  constructor(
    private actionPointService: ActionPointService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private usersService: UsersService,
    private uploadToS3Service: UploadToS3Service,
    private config: NgbDatepickerConfig
  ) {
    const current = new Date();
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  get comment() {
    return this.markAsComplete.get('comment');
  }

  get date() {
    return this.markAsComplete.get('date');
  }

  get attachment() {
    return this.markAsComplete.get('attachment') as FormArray;
  }


  ngOnInit() {
    this.initializeComplete();
    var today = new Date();
    this.selectedDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate())
  }

  initializeComplete() {
    this.markAsComplete = this.fb.group({
      comment: [''],
      date: [''],
      attachment: this.fb.array([])
    });
    this.craeteAttachmentArray()
  }

  craeteAttachmentArray() {
    (this.markAsComplete.get('attachment') as FormArray).push(
      this.fb.group({
        file: [''],
        image: [''],
        comment: [''],
      })
    );
  }

  deleteAttachment(attached) {
    const formArray = this.markAsComplete.get('attachment') as FormArray;
    const index = formArray.controls.findIndex(control => control === attached);
    formArray.removeAt(index);

    if (this.attachment.value.length === 0) {
      this.craeteAttachmentArray();
    }
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }


  attachmentUpload(event, attach, formArray) {
    if (event.target.files && event.target.files[0]) {
      attach.get('image').patchValue(event.target.files[0].name);
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        const url = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
      const extension = file.type.split('/').pop();

      this.subscriptions.push(
        this.uploadToS3Service.getUrlForUpload(extension, file.type)
          .subscribe((res: any) => {
            this.uploadData = res.payload;
            this.uploadToS3(this.uploadData, file, attach);
          }, (error: any) => {
          }),
      );
    }
  }

  uploadToS3(uploadData, file, attach) {
    this.subscriptions.push(
      this.uploadToS3Service.uploadToS3(uploadData, file)
        .subscribe((res: any) => {
          attach.get('file').patchValue(uploadData.fields.key);
        }, (error: any) => {
        }),
    );
  }

  markAsCompleteTask(): void {
    if (this.markAsComplete.valid) {
      const data = {
        comments: this.comment.value,
        completedDate: this.date.value.year + '-' + this.date.value.month + '-' + this.date.value.day,
      }
      const attach = [];
      this.attachment.value.forEach(element => {
        attach.push({ file: element.file, comment: element.comment })
      });
      if (attach.length) {
        data['attachments'] = attach;
      }
      this.subscriptions.push(
        this.actionPointService.markAsCompleteTasks(data, this.actionUuid)
          .subscribe((res: any) => {
            this.sendEventData('success');
          }, (error: any) => {

          }),
      );
    }
  }
}
