import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutsModule } from './layouts/layouts.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NewsPopupComponent } from './news-popup/news-popup.component';
import { CommonTaskViewComponent } from './common-task-view/common-task-view.component';
import { CommonActionPointViewComponent } from './common-action-point-view/common-action-point-view.component';
import { MatSelectModule } from '@angular/material/select';
import { CommonLookupUserComponent } from './common-lookup-user/common-lookup-user.component';
import { CommonInviteEmailComponent } from './common-invite-email/common-invite-email.component';
import { LoaderComponent } from './loader/loader.component';
import { CommonMarkCompleteComponent } from './common-mark-complete/common-mark-complete.component';
import { CommonDelegateTaskComponent } from './common-delegate-task/common-delegate-task.component';
import { AddAttachmentComponent } from './add-attachment/add-attachment.component';

const components = [
    NewsPopupComponent,
    CommonTaskViewComponent,
    CommonActionPointViewComponent,
    CommonLookupUserComponent,
    CommonInviteEmailComponent,
    CommonMarkCompleteComponent,
    AddAttachmentComponent,
    CommonDelegateTaskComponent,
    LoaderComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LayoutsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        MatSelectModule,
    ],
    declarations: [
        components
    ],
    exports: components,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule { }
