import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class UploadToS3Service {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  getUrlForUpload(extension, contentType) {
    return this.http.get(
      this.constantService.getUrl(`/content/signed?extension=${extension}&contentType=${contentType}`),
    );
  }

  uploadToS3(upladData, file) { 
    const formData = new FormData();
    Object.keys(upladData.fields).forEach(key => {
      formData.append(key, upladData.fields[key]);
    });
    formData.append('file', file);
    return this.http.post(`${upladData.url}`,formData,);
  }
}
