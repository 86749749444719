import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserRolesService } from '../../services/user-roles.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-common-lookup-user',
  templateUrl: './common-lookup-user.component.html',
  styleUrls: ['./common-lookup-user.component.scss']
})
export class CommonLookupUserComponent implements OnInit {

  searchControl: FormControl = new FormControl();
  addUsersRole: FormGroup;
  subscriptions: Subscription[] = [];
  public searchKeyword: string;
  public entityId: string;
  users = [];
  roles = [];
  itemsCount = 0;
  itemsPerPage = 10;
  currentPage = 1;
  showItemsCountPage = 0;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private rolesService: UserRolesService,
    private usersService: UsersService,
  ) { }

  get tasks() {
    return this.addUsersRole.get("roles") as FormArray;
  }

  ngOnInit() {
    this.getAllRoles();
    this.activatedRoute.params.subscribe(params => {
      if (params.entityUuid) {
        this.entityId = params.entityUuid;
        this.getAllUsers();
      }
    });
    this.subscriptions.push(this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.searchKeyword = value && value.length ? value.toLowerCase() : value;
      this.getAllUsers();
    }));
  }

  initializeUsersRole(data?) {
    this.addUsersRole = this.fb.group({
      roles: this.fb.array([]),
    });

    if (data && data.length) {
      data.forEach(element => {
        (this.addUsersRole.get('dependencies') as FormArray).push(
          new FormControl('', Validators.compose([Validators.required])),
        );
      });
    }
  }

  onChangeUsers(user) {
    user.selected = !user.selected;
    user.sentInvite = user.selected;
  }

  onChangeRole(event, uuid) {
    const index = this.users.findIndex((user) => user.uuid === uuid);
    this.users[index]['selectedRole'] = event.target.value;
  }

  navigateInvitationSent() {
    this.router.navigateByUrl(`onboard/invite/sent`);
  }

  pageChange(value: number): void {
    this.currentPage = value;
    this.getAllUsers();
  }

  getAllUsers(): void {
    const data = {
      page: this.currentPage,
    };
    if (this.searchKeyword) {
      data['q'] = this.searchKeyword;
    }
    if (this.entityId && this.entityId !== 'root') {
      data['entity'] = this.entityId;
    }

    this.subscriptions.push(
      this.usersService.getAllUsers(data).subscribe((res: any) => {
        if (res) {
          this.users = [];
          this.itemsCount = res.payload.count;
          this.showItemsCountPage = this.itemsCount < this.itemsPerPage ? this.itemsCount : this.itemsPerPage;
          res.payload.list.forEach(element => {
            element['selected'] = false;
            element['invited'] = false;
            element['sentInvite'] = false;
            element['selectedRole'] = '';
            this.users.push(element);
          });
        }
      }, (error: any) => {
      }),
    );
  }

  inviteSingleUser(uuid, role): void {
    const index = this.users.findIndex((user) => user.uuid === uuid);
    this.users[index].sentInvite = true;
    const data = {
      customer: uuid,
      role: role,
    };
    if (role) {
      this.subscriptions.push(
        this.usersService.inviteSingleUser({ invitations: [data] }, this.entityId)
          .subscribe((res: any) => {
            //this.users[index].invited = true;
            this.getAllUsers();
            /* this.users.forEach(element => {
              if (element && element.selected && element.selectedRole) {
                element.invited = true;
              }
            }); */
          }, (error: any) => {
          }),
      );
    }
  }

  inviteMultipleUser(): void {
    const data = [];
    this.users.forEach(element => {
      if (element && element.selected && element.selectedRole) {
        data.push({ customer: element.uuid, role: element['selectedRole'] });
      }
    });
    if (data.length) {
      this.subscriptions.push(
        this.usersService.inviteSingleUser({ invitations: data }, this.entityId)
          .subscribe((res: any) => {
            this.getAllUsers();
            /* this.users.forEach(element => {
              if (element && element.selected && element.selectedRole) {
                element.invited = true;
              }
            }); */
          }, (error: any) => {
          }),
      );
    }
  }

  getSlectedUser() {
    let count = 0;
    this.users.forEach(element => {
      if (element && element.selected) {
        count++;
      }
    });
    return count;
  }

  getAllRoles() {
    this.subscriptions.push(
      this.rolesService.getAllRoles()
        .subscribe((res: any) => {
          if (res) {
            this.roles = [];
            //this.roles = res.payload;
            res.payload.filter((element) => {
              if(element.active){
              this.roles.push(element);
              }
            })
          }
        }, (error: any) => {
        }),
    );
  }

}
