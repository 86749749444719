import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { EntityService } from 'src/app/shared/services/entity.service';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { ProxyEventService } from 'src/app/shared/services/proxy-event.service';

@Component({
  selector: 'app-common-left-sidebar',
  templateUrl: './common-left-sidebar.component.html',
  styleUrls: ['./common-left-sidebar.component.scss']
})
export class CommonLeftSidebarComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  public entities = [{dispalyName: 'Activision Inc.', uuid: '1'},{dispalyName: 'Ubisoft Co.', uuid: '2'},{dispalyName: 'Rockstar Games...', uuid: '3'}];
  active ='dashboard';
  insideActiveTab: string;
  profileInfo: any;
  public nameInitial: string;
  public profilePic: string;
  constructor(
    private entityService: EntityService,
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private proxyEventservice: ProxyEventService,
    private authService: AuthService,
  ) { 
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
    .subscribe(event => {
      this.active = this.router.url.split('/')[1];
        if(this.router.url.split('/').length > 1) {
          this.insideActiveTab = this.router.url.split('/')[2];
        }
        this.activatedRoute.params.subscribe(params => {
          if (params.entityUuid) {
            this.insideActiveTab = params.entityUuid;
          }
        });
    });
  }

  ngOnInit() {
    this.getAccountDetails();
    this.getAllEntities();
    this.subscriptions.push(this.proxyEventservice.closeSidebar.subscribe(val => {
      if (val) {
        this.getAccountDetails();
      }
    }));
    this.subscriptions.push(this.proxyEventservice.entityDetails.subscribe(val => {
      if (val) {
        this.getAccountDetails();
        this.getAllEntities();
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  activate(tab) {
    this.active = tab;
  }

  activateInsideOption(tab){
    this.insideActiveTab = tab;
    // this.router.navigateByUrl(`entities/${tab}/tasks`);
  }

  getAllEntities(): void {
    this.subscriptions.push(
      this.entityService.getAllDashboardEntities().subscribe((res: any) => {
        if (res) {
          this.entities = res.payload;
        }
      }, (error: any) => {
      }),
    );
  }

  getAccountDetails(): void {
    this.subscriptions.push(
      this.authService.getAccountDetails()
        .subscribe((res: any) => {
          if(res) {
            this.profileInfo = res.payload;
            this.gettingNameInitials(res.payload.firstName, res.payload.lastName);
            if(res.payload.profilePic) {
              this.profilePic = res.payload.profilePic;
            }
          }
        }, (error: any) => {
        }),
    );
  }

  gettingNameInitials(firstName, lastName) {
    const str1 = firstName.split('');
    const str2 = lastName.split('');
    this.nameInitial = str1[0] + str2[0];
  }

  navigateEntities() {
    if(this.active !== 'entities') {
      this.router.navigateByUrl(`entities`);
    }
  }

  navigateTasks() {
    if(this.active !== 'tasks') {
      this.router.navigateByUrl(`tasks/system`);
    }
  }

  navigateProfileSettings() {
    this.router.navigateByUrl(`profileSettings`);
  }

  logOut() {
    this.cookieService.clearCookies();
    this.router.navigateByUrl('/sessions/signin');
  }
}
