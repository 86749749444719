import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class ActionPointService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  getAllActionPoints(body) {
    const keys = Object.keys(body);
    if (keys.length) {
      const params = keys.reduce((acc, current) => { return acc + (current !== keys[0] ? '&' : '') + current + '=' + (Array.isArray(body[current] && body[current].length) ? body[current].reduce((a, c) => a + ',' + c) : body[current]); }, '?');
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}${params}`),
      );
    } else {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}`),
      );
    }

  }

  getActionPointDetails(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}`),
    );
  }

  getAssignedCustomerActions(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/assignments/${uuid}`),
    );
  }

  getActionPointByTask(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}?task=${uuid}`),
    );
  }

  getCustomActionPointByTask(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/custom?task=${uuid}`),
    );
  }

  delegateTasks(body, uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/delegate`), body
    );
  }

  ignoreTasks(uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/ignore`), {}
    );
  }

  addAttachment(body, uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/attachments`), body
    );
  }

  deleteAttachment(action, uuid) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${action}/attachments/${uuid}`), {}
    );
  }

  markAsCompleteTasks(body, uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/markAsCompleted`), body
    );
  }

  submitNewDeadline(body, uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/deadline`), body
    );
  }

  submitNewPivotDate(body, uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/pivot`), body
    );
  }

  getActionComments(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/comments`),
    );
  }

  addActionComments(uuid: string, body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/comments`), body
    );
  }

  reactivate(uuid: string) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/reactivate`), {}
    );
  }


  getActionMessages(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/messages`),
    );
  }

  addActionMessages(uuid: string, body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ACTIONS}${this.constantService.CUSTOMER}/${uuid}/messages`), body
    );
  }
  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}
