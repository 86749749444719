import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'src/app/shared/services/cookie.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        private cookieService: CookieService,
        public jwtHelper: JwtHelperService,
    ) { }
    
    intercept(
        request: HttpRequest<object>,
        next: HttpHandler,
    ): Observable<HttpEvent<object>> {
        if(request.url !== 'https://s3.ap-south-1.amazonaws.com/task-manager-upload-storage') {
            if (!request.headers.has('Content-Type')) {
                request = request.clone({
                    headers: request.headers.set('Content-Type', 'application/json'),
                });
            }
    
            request = request.clone({
                headers: request.headers.set('Accept', 'application/json'),
            });
            const token: string = this.cookieService.getCookie('token');
            if (token) {
    
                request = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + token),
                });
            }
        }
       

        return next.handle(request).pipe(
            map((event: HttpEvent<object>) => {
                if (event instanceof HttpResponse) {
                    // console.log("event--->>>", event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            })
        );
    }
}
