import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }


  skipEntityCreation() {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ONBOARDING}${this.constantService.ENTITIS}/skip`),
      {}
    );
  }

  createEntity(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ENTITIES}`),
      body
    );
  }

  toggleActivation(uuid: string) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/${uuid}/toggle-active`),
      {}
    );
  }

  updateEntity(body, uuid: string) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/${uuid}`),
      body
    );
  }

  getAllEntities(body?) {
    if(body) {
      const keys = Object.keys(body);
      const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ENTITIES}${params}`),
      );
    } else {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ENTITIES}`),
      );
    }
  }

  getAllDashboardEntities(body?) {
    if(body) {
      const keys = Object.keys(body);
      const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ENTITIES}/dashboard${params}`),
      );
    } else {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/dashboard`),
    );
  }
}

  getEntityByUuid(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/${uuid}`),
    );
  }

  getRootEntity() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/root`),
    );
  }

  entityMangementProceed() {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ONBOARDING}/entityManagement/proceed`),
      {}
    );
  }

  entityThemeSelection(body, entityId) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/${entityId}/theme`),
      body
    );
  }

  getModulesForAllEntities() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/modules`),
      {}
    );
  }
}
