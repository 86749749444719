import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ActionPointService } from '../../services/action-point.service';
import { UploadToS3Service } from '../../services/upload-to-s3.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent implements OnInit {

  @Input("actionUuid")
  public actionUuid: string;

  @Output("sendData")
  public sendData = new EventEmitter();

  public markAsComplete: FormGroup;
  public showAttachments = false;
  subscriptions: Subscription[] = [];
  public uploadData: any;

  constructor(
    private actionPointService: ActionPointService,
    private fb: FormBuilder,
    private uploadToS3Service: UploadToS3Service,
  ) { }

  get comment() {
    return this.markAsComplete.get('comment');
  }

  get attachment() {
    return this.markAsComplete.get('attachment') as FormArray;
  }


  ngOnInit() {
    this.initializeComplete();
  }

  initializeComplete() {
    this.markAsComplete = this.fb.group({
      attachment: this.fb.array([])
    });
    this.craeteAttachmentArray()
  }

  craeteAttachmentArray() {
    (this.markAsComplete.get('attachment') as FormArray).push(
      this.fb.group({
        file: [''],
        image: [''],
        comment: [''],
      })
    );
  }

  deleteAttachment(attached) {
    const formArray = this.markAsComplete.get('attachment') as FormArray;
    const index = formArray.controls.findIndex(control => control === attached);
    formArray.removeAt(index);

    if (this.attachment.value.length === 0) {
      this.craeteAttachmentArray();
    }
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }


  attachmentUpload(event, attach,formArray) {
    if (event.target.files && event.target.files[0]) {
      attach.get('image').patchValue(event.target.files[0].name);
       const file = event.target.files[0];
    
      var reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        const url = (<FileReader>event.target).result;
      }
      reader.readAsDataURL(event.target.files[0]);
      var extensionName=file.name.split('.').pop();
      //var fileType = file.type ? file.type : 'application/csv';
      var fileType = file.type ? file.type : 'application/'+extensionName;
      const extension = fileType.split('/').pop();

      this.subscriptions.push(
        this.uploadToS3Service.getUrlForUpload(extension, fileType)
          .subscribe((res: any) => {
            this.uploadData = res.payload;
            this.uploadToS3(this.uploadData, file, attach);
          }, (error: any) => {
          }),
      );
    }
  }

  uploadToS3(uploadData, file, attach) {
    this.subscriptions.push(
      this.uploadToS3Service.uploadToS3(uploadData, file)
        .subscribe((res: any) => {
          attach.get('file').patchValue(uploadData.fields.key);
        }, (error: any) => {
        }),
    );
  }

  addAttachment(): void {
    if (this.markAsComplete.valid) {
      const data = {}
      const attach = [];
      this.attachment.value.forEach(element => {
        attach.push({ file: element.file, comment: element.comment })
      });
      if (attach.length) {
        data['attachments'] = attach;
      }
      this.subscriptions.push(
        this.actionPointService.addAttachment(data, this.actionUuid)
          .subscribe((res: any) => {
            this.sendEventData('success');
          }, (error: any) => {

          }),
      );
    }
  }
}
