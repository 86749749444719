import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActionPointService } from '../../services/action-point.service';
import { UsersService } from '../../services/users.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-common-delegate-task',
  templateUrl: './common-delegate-task.component.html',
  styleUrls: ['./common-delegate-task.component.scss']
})
export class CommonDelegateTaskComponent implements OnInit {

  @Input("actionUuid")
  public actionUuid: string;

  @Output("sendData")
  public sendData = new EventEmitter();

  public entityId: string;
  public delegateTasks: FormGroup;
  public actionPointDetails: any;
  
  subscriptions: Subscription[] = [];
  totalMember = [];
  constructor(
    private fb: FormBuilder,
    private actionPointService: ActionPointService,
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
  ) { }

  get customer() {
    return this.delegateTasks.get('customer');
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.actionPointService.getActionPointDetails(this.actionUuid).subscribe((res: any) => {
        if (res) {
          this.actionPointDetails = res.payload;
          this.getAllUsers();
        }
      }, (error: any) => {
      });
    });

    this.initializeDelegateTasks();
  }

  initializeDelegateTasks() {
    this.delegateTasks = this.fb.group({
      customer: ['', Validators.required],
    });
  }

  getAllUsers(): void {
    this.subscriptions.push(
      this.usersService.getCustomersForDelegation({ entity: this.actionPointDetails.entity.uuid }).subscribe((res: any) => {
        
        if (res) {
          this.totalMember = res.payload.list;
        }
      }, (error: any) => {
      }),
    );
  }

  sendEventData(events) {
    this.sendData.emit(events);
  }


  delegate() {
    Object.keys(this.delegateTasks.controls).forEach(field => {
      const control = this.delegateTasks.get(field);
      control.markAsDirty();
    });
    if (this.delegateTasks.valid) {
      this.subscriptions.push(
        this.actionPointService.delegateTasks(this.delegateTasks.value, this.actionUuid).subscribe((res: any) => {
          if (res) {
            this.sendEventData('success');
            this.initializeDelegateTasks();
          }
        }, (error: any) => {
        }),
      );
    }
  }

}
