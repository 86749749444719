import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingLayoutComponent } from './shared/components/layouts/onboarding-layout/onboarding-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { DashboardLayoutComponent } from './shared/components/layouts/dashboard-layout/dashboard-layout.component';
import { SignupComponent } from './views/signup/signup.component';
import { AuthGaurd } from './shared/services/gaurds/auth.gaurd';
import { RolesResolverResolver } from './shared/resolvers/roles-resolver.resolver';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'entities',
    loadChildren: () => import('./views/entities/entities.module').then(m => m.EntitiesModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'users',
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'tasks',
    loadChildren: () => import('./views/tasks/tasks.module').then(m => m.TasksModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'actionPoints',
    loadChildren: () => import('./views/action-points/action-points.module').then(m => m.ActionPointsModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'notifications',
    loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'support',
    loadChildren: () => import('./views/support/support.module').then(m => m.SupportModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },
  {
    path: 'profileSettings',
    loadChildren: () => import('./views/profile-settings/profile-settings.module').then(m => m.ProfileSettingsModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./views/news/news.module').then(m => m.NewsModule),
    resolve: {
      hero: RolesResolverResolver
    }
  },

];
const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'sessions',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule)
      }
    ],
  },
  {
    path: '',
    component: OnboardingLayoutComponent,
    children: [
      {
        path: 'onboard',
        loadChildren: () => import('./views/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
    ],
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGaurd],
    canDeactivate:[AuthGaurd],
    children: routes,
  },
  
  // {
  //   path: 'sessions/signup',
  //   component: SignupComponent,
  //   children: routes,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
