import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  getAllUsers(body) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}${this.constantService.LOOKUP}${params}`),
    );
  }

  getNotifications() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/me/notifications`)
    );
  }

  getAllCustomers(body) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/all${params}`),
    );
  }

  getCustomersByEntity(uuid: string, body?) {
    if(body) {
      const keys = Object.keys(body);
      const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.CUSTOMERS}${this.constantService.ENTITY}/${uuid}${params}`),
      );
    } else {
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.CUSTOMERS}${this.constantService.ENTITY}/${uuid}`),
      );
    }
  }


  serachUsers() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/all`),
    );
  }

  usersAcrossEntities() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/all/entites`),
    );
  }

  getPendingEntityStatus() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/me/entity/pending`),
    );
  }

  getRoles() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/me/roles`),
    );
  }

  getCustomersForDelegation(body) {
    const keys = Object.keys(body);
    const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/all/${params}`),
    );
  }

  inviteSingleUser(body, entityId) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/${entityId}${this.constantService.COLLABORATOR}${this.constantService.INVITE}`), body
    );
  }

  inviteMultipleUser(body, entityId) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/${entityId}${this.constantService.COLLABORATOR}${this.constantService.INVITE}/multiple`), body
    );
  }

  inviteViaEmail(body, entityId) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ENTITIES}/${entityId}${this.constantService.COLLABORATOR}${this.constantService.INVITE}/email`), body
    );
  }

  removeUser(body, uuid: string) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}${this.constantService.ENTITY}/${uuid}/remove`), body
    );
  }

  addMemberToEntity(body, uuid) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/${uuid}/entities`), body
    );
  }

  deleteAccount() {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}/me`)
    );
  }

  transferTasks(body, uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}${this.constantService.ENTITY}/${uuid}/transfer`), body
    );
  }

  changeRole(body, uuid) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}${this.constantService.ENTITY}/${uuid}/changeRole`), body
    );
  }
}
