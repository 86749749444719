import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {

  API_URL: string = environment.API_ENDPOINT;

  SESSIONS = '/sessions';

  FORGETPASSWORD = '/forgetPassword';

  RESETPASSWORD = '/resetPassword';

  CUSTOMER = '/customer';

  CUSTOMERS = '/customers'

  SIGNUP  = '/signup';

  ONBOARDING = '/onboarding';

  ENTITIS = '/entitis';

  ENTITIES = '/entities';

  ENTITY = '/entity';

  ME = '/me';

  QUESTIONNAIRE = '/questionnaire';

  CHARACTERISTICS = '/characteristics';

  CLIENT = '/client';

  MODULES = '/modules';

  PAYMENT = '/payment';

  TASKS = '/tasks';

  FREQUENCY = '/frequency';

  LOOKUP = '/lookup';

  ROOT = '/root';

  COLLABORATOR = '/collaborator';

  INVITE = '/invite';

  NEWS = '/news';

  MEMBERS = '/members';

  ROLES = '/roles';

  ACTIONS = '/actions';

  NOTIFICATION = '/notification';

  SETTINGS = '/settings';

  

  constructor() { }
  getUrl(path: string, params: any[] = []) {
    return !params.length
      ? [this.API_URL, path].join('')
      : [[this.API_URL, path].join(''), params.join('/')].join('/');
  }

}
