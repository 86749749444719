import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingLayoutComponent } from './onboarding-layout/onboarding-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { CommonLeftSidebarComponent } from './dashboard-layout/common-left-sidebar/common-left-sidebar.component';
import { CommonHeaderComponent } from './dashboard-layout/common-header/common-header.component';
import { CommonRightSidebarComponent } from './dashboard-layout/common-right-sidebar/common-right-sidebar.component';


const components = [
  DashboardLayoutComponent,
  CommonLeftSidebarComponent,
  CommonRightSidebarComponent,
  CommonHeaderComponent,
  OnboardingLayoutComponent,
  AuthLayoutComponent,
  
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
  ],
  declarations: components,
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutsModule { }
