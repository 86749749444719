import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  getAllEntityTasks(body, entityId) {
    const keys = Object.keys(body);
    const params = keys && keys.reduce((acc, current) => { return acc + (current !== keys[0] ? '&' : '') + current + '=' + (Array.isArray(body[current]) && body[current].length ? body[current].reduce((a, c) => a + ',' + c) : body[current]); }, '?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ENTITY}/${entityId}${this.constantService.TASKS}${params}`),
    );
  }

  getAllTasks(body) {
    const keys = Object.keys(body);
    const params = keys && keys.reduce((acc, current) => { return acc + (current !== keys[0] ? '&' : '') + current + '=' + (Array.isArray(body[current]) && body[current].length ? body[current].reduce((a, c) => a + ',' + c) : body[current]); }, '?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}${params}`),
    );
  }

  getAllCustomTasks(body?) {
    if(body) {
      const keys = Object.keys(body);
      const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/custom${params}`),
      );
    } else {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/custom`),
    );
    }
  }
  getTaskDetails(body, uuid: string) {
    const keys = Object.keys(body);
    const params = keys && keys.reduce((acc, current) => { return acc + (current !== keys[0] ? '&' : '') + current + '=' + (Array.isArray(body[current]) && body[current].length ? body[current].reduce((a, c) => a + ',' + c) : body[current]); }, '?');
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/${uuid}${params}`),
    );
  }

  getTaskComments(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/${uuid}/comments`),
    );
  }

  addTaskComments(uuid: string, body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/${uuid}/comments`), body
    );
  }

  createCustomTasks(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/custom`), body
    );
  }

  getTaskCompletionRate() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/completion`)
    );
  }

  getTaskUpcomingCount() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/upcoming/count`)
    );
  }

  getAllContinousTasksByEntity(uuid) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.ENTITY}/${uuid}/frequencies`),
    );
  }

  updateContinousTasks(body, uuid) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.ENTITY}/${uuid}/frequencies`), body
    );
  }
  deleteCustomTasks(uuid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/custom/${uuid}`)
    );
  }
  
  updateCustomTasks(uuid: string, body) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/custom/${uuid}`), body
    );
  }
  getCustomTasksDetails(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.TASKS}${this.constantService.CUSTOMER}/custom/${uuid}`)
    );
  }
}
