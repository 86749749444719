import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class UserRolesService {

  constructor(
    public http: HttpClient,
    public constantService: ConstantService,
  ) { }

  getAllRoles(body?) {
    if(body) {
      const keys = Object.keys(body);
      const params = keys.reduce((acc, current) => {return acc + (current !== keys[0] ? '&' : '' )+current+'='+  (Array.isArray(body[current]) ?  body[current].reduce((a, c) => a + ',' + c) : body[current]);},'?');
      return this.http.get(
        this.constantService.getUrl(`${this.constantService.ROLES}${this.constantService.CUSTOMER}${params}`),
      );
    } else {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ROLES}${this.constantService.CUSTOMER}`),
    );
    }
  }

  getAllPermission() {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ROLES}${this.constantService.CUSTOMER}/permissions/master`),
    );
  }

  addRole(body) {
    return this.http.post(
      this.constantService.getUrl(`${this.constantService.ROLES}${this.constantService.CUSTOMER}`), body
    );
  }

  getRoleByUuid(uuid: string) {
    return this.http.get(
      this.constantService.getUrl(`${this.constantService.ROLES}${this.constantService.CUSTOMER}/${uuid}`),
    );
  }

  updateRole(uuid: string, body) {
    return this.http.put(
      this.constantService.getUrl(`${this.constantService.ROLES}${this.constantService.CUSTOMER}/${uuid}`), body
    );
  }

  deleteRole(uuid: string) {
    return this.http.delete(
      this.constantService.getUrl(`${this.constantService.ROLES}${this.constantService.CUSTOMER}/${uuid}`)
    );
  }

  changeRole(uuid: string, body) {
    return this.http.patch(
      this.constantService.getUrl(`${this.constantService.CUSTOMERS}${this.constantService.ENTITY}/${uuid}/changeRole`), body
    );
  }
}
