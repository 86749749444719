import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActionPointService } from '../../services/action-point.service';
import { Location } from '@angular/common';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from 'src/app/shared/services/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-common-action-point-view',
  templateUrl: './common-action-point-view.component.html',
  styleUrls: ['./common-action-point-view.component.scss']
})
export class CommonActionPointViewComponent implements OnInit {

  subscriptions: Subscription[] = [];
  public delegateTasks: FormGroup;
  public changeDeadline: FormGroup;
  public pivotDateInput: FormGroup;
  public commentBox: FormGroup;
  public markAsComplete: FormGroup;
  public actionMessages = [];
  actionIndex: string;
  actionUuid: string;
  public taskUuid: string;
  public entityUuid: string;
  public showAttachments = false;
  public actionComments = [];
  public totalMember = [];
  actionPointDetails: any;

  selectedOption = 'info';
  constructor(
    private activatedRoute: ActivatedRoute,
    private actionPointService: ActionPointService,
    private location: Location,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private usersService: UsersService,
    protected sanitizer: DomSanitizer
  ) { }

  get customer() {
    return this.delegateTasks.get('customer');
  }

  get deadline() {
    return this.changeDeadline.get('deadline');
  }

  get pivot() {
    return this.pivotDateInput.get('deadline');
  }

  get message() {
    return this.commentBox.get('message');
  }

  get comment() {
    return this.markAsComplete.get('comment');
  }

  get date() {
    return this.markAsComplete.get('comment');
  }

  get attachment() {
    return this.markAsComplete.get('attachment') as FormArray;
  }

  get historyList() {
    if(!this.actionPointDetails) return [];
    return this.actionPointDetails.history;
  }

  ngOnInit() {
    this.initializeDelegateTasks();
    this.initializeDeadline();
    this.initializePivotDate();
    this.initializeComment();
    this.initializeComplete();
    this.activatedRoute.params.subscribe(params => {
      if (params.actionUuid) {
        this.actionUuid = params.actionUuid;
        this.getActionPointDetails();
        this.getActionComments();
        this.getActionMessages();
      }
      if (params.index) {
        this.actionIndex = params.index;
      }
      if (params.taskUuid) {
        this.taskUuid = params.taskUuid;
      }
      if (params.entityUuid) {
        this.entityUuid = params.entityUuid;
      }
    });
  }

  initializeDelegateTasks() {
    this.delegateTasks = this.fb.group({
      customer: ['', Validators.required],
    });
  }

  hasPermission(actions: string[]) {
    if(!this.actionPointDetails) return false;
    const permissionsString = sessionStorage.getItem("roles");
    if(permissionsString) {
      const permissions = JSON.parse(permissionsString);
      const entityPermissions = permissions[this.actionPointDetails.entity.uuid];
      if(entityPermissions) {
        if(entityPermissions.owner) {
          return true;
        }
        const status = actions.reduce((acc, action) => {
          if(entityPermissions.permissions.indexOf(action) !== -1) {
            acc = true;
          }
          return acc;
        }, false);
        if(status) {
          return true;
        }
      }
    }
    return false;
  }

  initializeDeadline() {
    this.changeDeadline = this.fb.group({
      deadline: ['', Validators.required],
    });
  }

  initializePivotDate() {
    this.pivotDateInput = this.fb.group({
      deadline: ['', Validators.required],
    });
  }

  initializeComment() {
    this.commentBox = this.fb.group({
      message: [''],
    });
  }

  initializeComplete() {
    this.markAsComplete = this.fb.group({
      comment: [''],
      date: [''],
      attachment: this.fb.array([])
    });
    this.craeteAttachmentArray()
  }

  craeteAttachmentArray() {
    (this.markAsComplete.get('attachment') as FormArray).push(
      this.fb.group({
        image: [''],
        comment: [''],
      })
    );
  }

  deleteAttachment(attached) {
    const formArray = this.markAsComplete.get('attachment') as FormArray;
    const index = formArray.controls.findIndex(control => control === attached);
    formArray.removeAt(index);

    if (this.attachment.value.length === 0) {
      this.craeteAttachmentArray();
    }
  }

  getColor(color) {
    if(color==='later'){
      return 'faraway-color';
    }
    if(color) {
      return color+'-color';
    }
  }
  
  getIgnore(ignore) {
    if (ignore) {
      return 'ignored-color';
    }
  }

  getBackgroundColor(color) {
    if(color==='later'){
      return 'faraway-background';
    }
    return color+'-background';
  }
  getIgnoreBackgroundColor(ignore) {
    if (ignore) {
      return 'ignored-background';
    }
  }
  showActionOption(option) {
    this.selectedOption = option;
  }

  getActionPointDetails() {
    this.subscriptions.push(
      this.actionPointService.getActionPointDetails(this.actionUuid).subscribe((res: any) => {
        if (res) {
          this.actionPointDetails = res.payload;
          this.getAllUsers();
        }
      }, (error: any) => {
      }),
    );
  }

  stripTime(dateTime: string) {
    if (!dateTime) {
      return "";
    }
    return dateTime.substr(0, 10)
  }

  getAllUsers(): void {
    this.subscriptions.push(
      this.usersService.getCustomersForDelegation({ entity: this.actionPointDetails.entity.uuid }).subscribe((res: any) => {
        if (res) {
          this.totalMember = [];
          this.totalMember = res.payload.list;
        }
      }, (error: any) => {
      }),
    );
  }

  delegate() {
    Object.keys(this.delegateTasks.controls).forEach(field => {
      const control = this.delegateTasks.get(field);
      control.markAsDirty();
    });
    if (this.delegateTasks.valid) {
      this.subscriptions.push(
        this.actionPointService.delegateTasks(this.delegateTasks.value, this.actionUuid).subscribe((res: any) => {
          if (res) {
            this.modalService.dismissAll();
            this.initializeDelegateTasks();
            this.getActionPointDetails();
          }
        }, (error: any) => {
        }),
      );
    }
  }

  submitNewDeadline() {
    Object.keys(this.changeDeadline.controls).forEach(field => {
      const control = this.changeDeadline.get(field);
      control.markAsDirty();
    });
    if (this.changeDeadline.valid) {
      const data = {
        deadline: moment(this.deadline.value.day + '-' + this.deadline.value.month + '-' + this.deadline.value.year, "D-M-YYYY")
          .format("DD-MM-YYYY")
      }
      this.subscriptions.push(
        this.actionPointService.submitNewDeadline(data, this.actionUuid).subscribe((res: any) => {
          if (res) {
            this.modalService.dismissAll();
            this.getActionPointDetails();
            this.initializeDeadline();
          }
        }, (error: any) => {
        }),
      );
    }
  }

  submitNewPivotDate() {
    Object.keys(this.pivotDateInput.controls).forEach(field => {
      const control = this.pivotDateInput.get(field);
      control.markAsDirty();
    });
    if (this.pivotDateInput.valid) {
      const data = {
        date: moment(this.pivot.value.day + '-' + this.pivot.value.month + '-' + this.pivot.value.year, "D-M-YYYY")
          .format("DD-MM-YYYY")
      }
      this.subscriptions.push(
        this.actionPointService.submitNewPivotDate(data, this.actionUuid).subscribe((res: any) => {
          if (res) {
            this.modalService.dismissAll();
            this.getActionPointDetails();
            this.initializePivotDate();
          }
        }, (error: any) => {
        }),
      );
    }
  }

  ignoreTasks() {
    this.actionPointService.ignoreTasks(this.actionUuid).subscribe((res: any) => {
      this.getActionPointDetails();
    });
  }

  openDelegateModal(delegateModal) {
    this.modalService.open(delegateModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'sm' })
      .result.then((result) => {
        if (result === 'success') {
          this.initializeDelegateTasks();
          this.actionPointDetails();
        }
      });
  }

  openApprovalModal(approvalModal) {
    this.modalService.open(approvalModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'sm' })
      .result.then((result) => {
        if (result === 'ok') {
        }
      });
  }

  openDeadlineModal(deadlineModal) {
    this.modalService.open(deadlineModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'sm' })
      .result.then((result) => {
        if (result === 'success') {
          this.initializeDeadline();
        }
      });
  }

  openPivotModal(modal) {
    this.modalService.open(modal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'sm' })
      .result.then((result) => {
        if (result === 'success') {
          this.initializePivotDate();
        }
      });
  }

  formatDeadline(date) {
    return moment(date).format("LL");
  }

  reactivate(actionPointDetails) {
    this.actionPointService.reactivate(actionPointDetails.uuid).subscribe((res: any) => {
      if (res) {
        this.getActionPointDetails();
      }
    });
  }

  openMarkAsCompleteModal(completeModal) {
    this.modalService.open(completeModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'md' })
      .result.then((result) => {
        if (result === 'success') {
          this.getActionPointDetails();
        }
      });
  }

  openAttachmentModal(attachmentModal) {
    this.modalService.open(attachmentModal,
      { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true, size: 'md' })
      .result.then((result) => {
        if (result === 'success') {
          this.getActionPointDetails();
        }
      });
  }

  deletePersistedAttachment(uuid) {
    this.actionPointService.deleteAttachment(this.actionUuid, uuid)
      .subscribe((res: any) => {
        this.getActionPointDetails();
      });
  }

  getAttachmentName(name) {
    return name.split("/").pop();
  }
  getActionComments() {
    this.subscriptions.push(
      this.actionPointService.getActionComments(this.actionUuid).subscribe((res: any) => {
        if (res) {
          this.actionComments = res.payload;
        }
      }, (error: any) => {
      }),
    );
  }

  addComments() {
    if (this.message.valid) {
      this.subscriptions.push(
        this.actionPointService.addActionComments(this.actionUuid, { comment: this.message.value }).subscribe((res: any) => {
          if (res) {
            this.getActionComments();
            this.initializeComment();
          }
        }, (error: any) => {
        }),
      );
    }
  }

  getActionMessages() {
    this.subscriptions.push(
      this.actionPointService.getActionMessages(this.actionUuid).subscribe((res: any) => {
        if (res) {
          this.actionMessages = res.payload;
        }
      }, (error: any) => {
      }),
    );
  }

  addActionMessages() {
    if (this.message.valid) {
      this.subscriptions.push(
        this.actionPointService.addActionMessages(this.actionUuid, { comment: this.message.value }).subscribe((res: any) => {
          if (res) {
            //this.getActionComments();
            this.initializeComment();
            this.getActionMessages()

          }
        }, (error: any) => {
        }),
      );
    }
  }


  back() {
    this.location.back();
  }
}
