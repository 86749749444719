import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit {

  public step;
  public header: any;
  public title: string;
  public subTitle: string;
  public questionIcon = false;
  public pageInfo;

  private urlToKeyMap = new Map([
    ['dashboard', { title: 'Dashboard', subTitle: 'Access all areas of the web app from this screen',  questionTip: true}],
    ['users', { title: 'Users', subTitle: 'Take a look at all the members within all your entities on this screen',  questionTip: true}],
    ['roleManagement', { title: 'Users', subTitle: 'Take a look at all the members within all your entities on this screen',  questionTip: true}],
    ['createRole', { title: 'Users', subTitle: 'Take a look at all the members within all your entities on this screen',  questionTip: true}],
    ['entities', { title: 'My Entities', subTitle: 'Access all your entities from this screen',  questionTip: true}],
    ['tasks', { title: 'Tasks', subTitle: 'All the Tasks from every entity are accessible here',  questionTip: true}],
    ['customTasks', { title: 'Custom Tasks', subTitle: 'All the Tasks from every entity are accessible here',  questionTip: true}],
    ['create', { title: 'Custom Tasks', subTitle: 'All the Tasks from every entity are accessible here',  questionTip: true}],
  ]);
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
  ) {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    )
    .subscribe(event => {
      this.titleService.setTitle(event['title']);
      this.pageInfo = event;
    });
   }

  ngOnInit() {
    
  }

  navigateSupport() {
    this.router.navigateByUrl(`support`);
  }

  navigateSettings() {
    this.router.navigateByUrl(`settings`);
  }


}
